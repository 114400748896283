<template>
  <div class="mx-4 elevation-10 rounded-lg pa-2" >
    <v-row dense>
      <v-col cols="12" sm="8" md="8">
        <v-data-table
            :headers="headersSales"
            :items="computedSalesByDivision"
            hide-default-footer
        >

          <template v-slot:[`item.id`]="{ item }">
            {{item.shortDesc}}
          </template>

          <template v-slot:[`item.description`]="{ item }">
            <div class="fill-height">
              <span class="grey--text mr-1">{{item.shortDesc}}</span>{{item.description}}
            </div>
          </template>

          <template v-slot:[`item.sumResult`]="{ item }">
            <div class="fill-height">
              <div class="font-weight-bold text-end">
                {{ formattedCurrencyValue(item.sumResult) }}
              </div>
              <div class="grey--text text-end caption">
                avg<span class="ml-1">{{formattedCurrencyValue(item.salesAvg)}}</span>
              </div>
            </div>
          </template>

          <template v-slot:[`item.kasMasuk`]="{ item }">
            <div class="fill-height">
              <div v-for="(itemDetil, index) in item.accAccountsDebit.sort((a, b) => (a.kode1 < b.kode1)? 1: -1)" :key="index">
                <v-flex class="d-flex"  @click="kasMasukClick(itemDetil)">
                  <span :class="itemDetil.accountDesc.toLowerCase().includes('kas kecil')? 'grey--text':'' ">
                    {{ itemDetil.accountDesc}}
                  </span>
                  <v-spacer></v-spacer>
                  <span class="text-end" :class="itemDetil.accountDesc.toLowerCase().includes('kas kecil')?'grey--text': ''">
                    {{ formattedCurrencyValue(itemDetil.value) }}
                  </span>
                </v-flex>
              </div>

              <div class="font-weight-bold blue--text mt-1">
                <v-flex class="d-flex" >
                  <span>
                    TOTAL
                  </span>
                  <v-spacer></v-spacer>
                  <span class="text-end">
                    {{ formattedCurrencyValue(item.accAccountsDebit.map( x => x.value).reduce((sumResult, nextValue) => sumResult+nextValue, 0)) }}
                  </span>
                </v-flex>
              </div>
            </div>
          </template>

          <template slot="body.append">
            <tr class="pink--text">
              <th class="subtitle-2">Totals</th>
              <th class="subtitle-2  text-end">{{ formattedCurrencyValue(parseInt(sumFieldSales('sumResult'))) }}</th>
              <th class="subtitle-2 text-end">{{ formattedCurrencyValue(parseInt(sumFieldJournal())) }}</th>
            </tr>
          </template>

        </v-data-table>
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-btn-toggle
            v-model="toggleValueBiaya"
            dense
            class="mb-2"
            color="blue"
        >
          <v-btn value="biayaTertinggi" small>
            <span class="caption font-weight-bold">Biaya Tertinggi</span>
          </v-btn>

        </v-btn-toggle>

        <v-data-table
            :headers="headersJournal"
            :items="computedJournal"
            dense
            @click:row="rowTableClick"        >
          <template v-slot:[`item.value`]="{ item }">
            <div class="fill-height">
              <div class="text-end">
                {{ formattedCurrencyValue(parseInt(item.value)) }}
              </div>
            </div>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogShow" width="600px">
      <v-card class="pa-2">
        <v-card-subtitle class="my-1">
          <span class="font-weight-bold">{{ this.itemSelected.accountDesc }}</span>
        </v-card-subtitle>
        <v-card-text>
          <v-data-table
              :headers="headersAccountDetil"
              :items="itemsAccountDetil"
              dense
              :page.sync="currentPageAccountDetil"
              :items-per-page.sync="pageSizeAccountDetil"
          >
            <template v-slot:[`item.number`]="{ index }">
              {{ (index + ((currentPageAccountDetil-1)*pageSizeAccountDetil) +1 )}}
            </template>
            <template v-slot:[`item.description`]="{ item }">
              <div class="caption">
                {{ formatAccountDetilDescription(item.description) }}
              </div>
            </template>

            <template v-slot:[`item.sumResult`]="{ item }">
              <div class="text-end">
                {{ formattedCurrencyValue(parseInt(item.amountDebit-item.amountCredit)) }}
              </div>
            </template>
            <template slot="body.append">
              <tr class="pink--text">
                <th class="subtitle-2"></th>
                <th class="subtitle-2">Total All Items</th>
                <th class="subtitle-2  text-end">{{ formattedCurrencyValue(parseInt(sumFieldAccountDetil())) }}</th>
              </tr>
            </template>

          </v-data-table>

        </v-card-text>
        <v-card-text>
          <div>
            <v-data-table
                :headers="headersAccountDetil"
                :items="itemsAccountDetilLast6Month"
                hide-default-footer
                dense
            >
              <template v-slot:[`item.number`]="{ index }">
                {{ (index+1 )}}
              </template>

              <template v-slot:[`item.description`]="{ item }">
                <div class="subtitle-2">
                  {{ item.remark1 }}
                </div>
              </template>
              <template v-slot:[`item.sumResult`]="{ item }">
                <div class="text-end subtitle-2">
                  {{ formattedCurrencyValue(parseInt(item.valDouble1)) }}
                </div>
              </template>
            </v-data-table>

          </div>

          <div class="blue-grey lighten-3 rounded mt-2 elevation-10">
            <v-sparkline
                class="mt-2"
                :value="sprarkLineValue"
                :gradient="gradient"
                :smooth="radius || false"
                :padding="padding"
                :line-width="width"
                :stroke-linecap="lineCap"
                :gradient-direction="gradientDirection"
                :fill="fill"
                :type="type"
                :auto-line-width="autoLineWidth"
                auto-draw
            >

              <template v-slot:label="item">
                {{ formattedCurrencyValue(item.value) }}
              </template>

            </v-sparkline>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogClose" small class="primary" rounded>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import AccJournaldItemsService from "@/services/apiservices/acc-journald-items-service";
import FtSaleshFilter from "@/models/payload/ft-salesh-filter";

const gradients = [
  ['#222'],
  ['#42b3f4'],
  ['red', 'orange', 'yellow'],
  ['purple', 'violet'],
  ['#00c6ff', '#F0F', '#FF0'],
  ['#f72047', '#ffd200', '#1feaea'],
]

export default {
  name: "DashboardFinanceReport",
  props:{
    salesByDivision:[],
    itemsAccAccount: [],
    itemsAccJournald: [],
    dateDiffFromRange: Number,
    ftSaleshFilter: Object

  },
  data(){
    return{
      dialogShow: false,

      currentPageAccountDetil: 1,
      pageSizeAccountDetil: 10,

      toggleValueBiaya: 'biayaTertinggi',
      headersSales: [
        { text: 'Cabang/Division', value: 'description', width:"20%", sortable: false },
        { text: 'Sales Only', value: 'sumResult', width:"15%", sortable: false },
        { text: 'Pelunasan (Cash Based)', value: 'kasMasuk', width:"45%", sortable: false },
        // { text: 'Kas Keluar', value: 'kasKeluar', width:"2%", sortable: false },
      ],
      headersJournal: [
        { text: 'Akun', value: 'accountDesc', width:"60%", sortable: false },
        { text: 'Nilai(D/K)', value: 'value', width:"40%", sortable: false },
      ],
      headersAccountDetil: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'Description', value: 'description', width:"80%", sortable: false },
        { text: 'Debit(+)/Credit(-)', value: 'sumResult', width:"40%", sortable: false },
      ],
      itemSelected: '',
      itemsAccountDetilLast6Month: [],
      itemsAccountDetil: [],

      width: 2,
      radius: 10,
      padding: 8,
      lineCap: 'round',
      gradient: gradients[5],
      value: [0, 2, 5],
      gradientDirection: 'top',
      gradients,
      fill: false,
      type: 'trend',
      autoLineWidth: false,

    }
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },
    computedSalesByDivision(){
      let indexJournal = 0
      let itemsTotalSalesAndCash = []

      this.salesByDivision.map( item => {
        try {
          item.dateDiff = this.dateDiffFromRange +1
          item.salesAvg = Math.round(item.sumResult / item.dateDiff)
        }catch (e) {
          e.toString()
        }

        let itemsSumJournald = []
        this.itemsAccAccount.filter(x => x.coaType =='CASH_BA' && x.postEdit==true).forEach( itemAccAccount => {

          let valuesRp = 0
          this.itemsAccJournald.filter(x => x.countResult==item.id && x.id==itemAccAccount.id ).forEach( itemAccJourald =>{
            if (itemAccAccount.description.includes('#DK') || itemAccAccount.description.includes('#D') ) {
              valuesRp += itemAccJourald.valDouble1
            }
            if (itemAccAccount.description.includes('#DK') || itemAccAccount.description.includes('#K') ) {
              valuesRp -= itemAccJourald.valDouble2
            }

          })

          let newItem = {id: indexJournal++, fdivisionBean: 0, accountId: itemAccAccount.id, accountDesc: itemAccAccount.description, value: valuesRp}

          if (newItem.value !=0 && newItem.accountDesc.includes("#")){
            itemsSumJournald.push(newItem)
          }
        })

        item.accAccountsDebit = itemsSumJournald

        itemsTotalSalesAndCash.push(item)

      })

      return itemsTotalSalesAndCash
    },

    computedJournal(){
      let indexJournal = 0

      // console.log(JSON.stringify(this.itemsAccAccount))

      let itemsSumJournald = []
      this.itemsAccAccount.filter(x => x.coaType =='EXPENSE' && x.postEdit==true).forEach( itemAccAccount => {

        let valuesRp = 0
        // this.itemsAccJournald.filter(x => x.accAccountBean==itemAccAccount.id).forEach( itemAccJourald =>{
        //   valuesRp += itemAccJourald.amountDebit
        // })
        let itemsAccJournaldFiltered = Object.assign({}, itemsSumJournald)
        if (this.currentUser.organizationLevel === "DIV") {
          itemsAccJournaldFiltered.filter(x => x.countResult == this.currentUser.fdivisionBean)
        }
        this.itemsAccJournald.filter(x => x.id==itemAccAccount.id).forEach( itemAccJourald =>{
          valuesRp += (itemAccJourald.valDouble1 - itemAccJourald.valDouble2)
        })

        let newItem = {id: indexJournal++, accountId: itemAccAccount.id, accountDesc: itemAccAccount.description, value: valuesRp}

        if (newItem.value !=0){
          itemsSumJournald.push(newItem)
        }
      })

      return itemsSumJournald.sort((a, b) => (a.value > b.value)? -1: 1)
    },

    sprarkLineValue(){
      let values = []
      this.itemsAccountDetilLast6Month.forEach( x => {
        values.push(x.valDouble1-x.valDouble2)
        x.toString()
      })
      // console.log(values)
      return values
    },

  },
  methods:{
    kasMasukClick(itemDetil){

      const newItem  ={"id":itemDetil.id,"accountId":itemDetil.accountId,"accountDesc": itemDetil.accountDesc,"value":itemDetil.sumResult}

      // console.log(JSON.stringify(itemDetil))
      this.rowTableClick(newItem)
    },
    rowTableClick(item){
      this.itemSelected = item
      // console.log(JSON.stringify(item))

      let ftSaleshFilter = new FtSaleshFilter()
      ftSaleshFilter = Object.assign({}, this.ftSaleshFilter)
      ftSaleshFilter.fmaterialIds = []
      ftSaleshFilter.fmaterialIds.push(item.accountId)

      this.itemsAccountDetil =[]
      AccJournaldItemsService.getAllByTrDateBetweenAndAccAccountIds(ftSaleshFilter).then(
          response =>{
            // console.log(JSON.stringify(response.data))
            this.itemsAccountDetil = response.data
          }
      )

      let ftSaleshFilterMonth = new FtSaleshFilter()
      ftSaleshFilterMonth = Object.assign({}, this.ftSaleshFilter)

      let startDate = new Date(this.ftSaleshFilter.invoiceDateTo.getFullYear(), this.ftSaleshFilter.invoiceDateTo.getMonth(), 1)
      let newStartDate6Month = new Date(startDate.getFullYear(), startDate.getMonth()-5, 1)
      ftSaleshFilterMonth.invoiceDateFrom = newStartDate6Month

      let endDate = new Date(startDate.getFullYear(), startDate.getMonth()+1, 0)
      ftSaleshFilterMonth.invoiceDateTo = endDate

      ftSaleshFilterMonth.fmaterialIds = []
      ftSaleshFilterMonth.fmaterialIds.push(item.accountId)

      this.itemsAccountDetilLast6Month = []
      AccJournaldItemsService.sumAllByTrDateBetweenAndAccAccountIdsGroupByAccAccountAndMonth(ftSaleshFilterMonth).then(
          response =>{
            this.itemsAccountDetilLast6Month = response.data.sort((a, b) => (a.remark1 > b.remark1)? 1:-1).map( x =>{
              let newValue = x
              x.id = Math.random()
              return newValue
            })

          }
      )
      this.dialogShow = true
    },
    dialogClose(){
      this.dialogShow = false
    },

    formattedCurrencyValue(value){
      if (!value){return 0 }
      return parseFloat(value).toLocaleString()
    },
    sumFieldSales(key) {
      // sum data in give key (property)
      return this.salesByDivision.reduce((a, b) => a + (b[key] || 0), 0)
    },

    sumFieldJournal() {
      // sum data in give key (property)
      let totalValue = 0
      this.salesByDivision.forEach(item => {
          totalValue += item.accAccountsDebit.map( x=>x.value).reduce((sumResult, nextValue) => sumResult+nextValue, 0)
      })
      return totalValue
    },
    sumFieldAccountDetil(){
      let totalValue = 0
      this.itemsAccountDetil.forEach(item => {
        totalValue += item.amountDebit-item.amountCredit
      })
      return totalValue
    },

    formatAccountDetilDescription(value){
      // let position = value.search('TrBy-')+5
      // return value.substring(position, value.length)

      let arrString = value.split('|');
      return  arrString[3]
    },
  }
}
</script>

<style scoped>

</style>