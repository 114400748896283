<template>
  <div class="mx-4 elevation-10 rounded-lg pa-4" >
    <v-row>
      <v-col cols="12" sm="3" md="3">
       <span class="font-weight-bold ml-4 orange--text">SALES TREND</span>
      </v-col>
      <v-col cols="12" sm="9" md="9">
        <v-btn-toggle
            v-model="toggleValue"
            dense
        >
          <v-btn value="last3months"  @click="toggleClick('last3months')">
            <span class="caption font-weight-bold">Last 3 Months</span>
          </v-btn>

          <v-btn value="last6months" @click="toggleClick('last6months')">
            <span class="caption font-weight-bold">Last 6 Months</span>
          </v-btn>

        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <span class="font-weight-bold">Branch/Division</span>
        <apexcharts width="350" :options="chartOptions" :series="seriesDivision"></apexcharts>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <span class="font-weight-bold">Jenis Customer</span>
        <apexcharts width="350" :options="chartOptions" :series="seriesCustomerGroup"></apexcharts>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FtSaleshExtService from "@/services/apiservices/ft-sales-ext-service";
import VueApexCharts from 'vue-apexcharts'
import FtSaleshFilter from "@/models/payload/ft-salesh-filter";

export default {
  name: "DashboardSalesTrend",
  components: {apexcharts: VueApexCharts},
  props:{
    salesByDivision:[]
  },
  data(){
    return{
      toggleValue: '',
      // ftSaleshFilter: '',
      seriesDivision: [
        {
          name: 'A',
          data: [1, 40, 500000]
        },
        {
          name: 'B',
          data: [1, 32, 1000000]
        },
      ],
      seriesCustomerGroup: [
        {
          name: 'A',
          data: [1, 40, 500000]
        },
        {
          name: 'B',
          data: [1, 32, 1000000]
        },
      ],

      strMonths: ['01', '02', '03'],

      chartOptions: {

        chart: {
          height: 350,
          type: 'area',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        // xaxis: {
        //   type: 'datetime',
        //   categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
        // },
        xaxis: {
          type: 'string',
          // categories: ["01", "02", "03", "04", "05", "06"]
          categories: this.strMonths
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy'
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 320,
              zoom: {
                enabled: true
              },
            },
          }
        }]
      },

    }
  },
  watch:{
    // toggleValue:{
    //   handler: function (value){
    //     // console.log(value)
    //     this.runExtendedFilter(this.ftSaleshFilter)
    //     this.toggleClick(value)
    //   }
    // }
  },
  computed:{

    computedChartOptionsDivision(){
      let chartOptions = {
        chart: {
          height: 250,
          type: 'area',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'string',
          // categories: ["01", "02", "03", "04", "05", "06"]
          categories: this.strMonths
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 320,
              zoom: {
                enabled: true
              },
            },
          }
        }]

      }
      return chartOptions
    },

    computedChartOptionsCustomerGroup(){
      let chartOptions = {
        chart: {
          height: 250,
          type: 'area',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'string',
          // categories: ["01", "02", "03", "04", "05", "06"]
          categories: this.strMonths
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 320,
              zoom: {
                enabled: true
              },
            },
          }
        }]

      }
      return chartOptions
    }

  },
  methods:{
    runExtendedFilter(ftSaleshFilter) {
      FtSaleshExtService.getAllFtSaleshExtByDivisionAndMonth(ftSaleshFilter).then(
          response =>{
            // console.log(JSON.stringify(response.data))

            let divisionGroup = []
            let strMonths = []
            response.data.forEach( x => {
              divisionGroup.push({id: x.id, shortDesc: x.shortDesc, description: x.description})
              strMonths.push(x.remark1)
            })

            let uniqueStrMonths = [...new Set(strMonths)];
            this.strMonths = uniqueStrMonths.sort((a, b) => (a > b)? 1:-1)
            // console.log(JSON.stringify(this.strMonths))

            let uniqueDivisions = divisionGroup.filter((arr, index, self) =>
                index === self.findIndex((t) => (t.id === arr.id && t.shortDesc === arr.shortDesc)))


            this.seriesDivision = []
            uniqueDivisions.forEach( x =>{
                  let theName = response.data.filter(f => f.id === x.id)[0].description
                  // console.log(theName)

                  let theData = []

                  response.data.forEach( f => {
                    if (f.id === x.id) {
                      theData.push(parseInt(f.sumResult))
                    }
                  })
                  this.seriesDivision.push({
                    name: theName,
                    data: theData
                  })

                }
            )

            this.seriesDivision.sort((a,b) => (a.shortDesc > b.shortDesc)? 1: -1)
          }
      )

      FtSaleshExtService.getAllFtSaleshExtByCustomerGroupAndMonth(ftSaleshFilter).then(
          response =>{
            // console.log(JSON.stringify(response.data))

            let divisionGroup = []
            let strMonths = []
            response.data.forEach( x => {
              divisionGroup.push({id: x.id, shortDesc: x.shortDesc, description: x.description})
              strMonths.push(x.remark1)
            })

            let uniqueStrMonths = [...new Set(strMonths)];
            this.strMonths = uniqueStrMonths.sort((a, b) => (a > b)? 1:-1)
            // console.log(JSON.stringify(this.strMonths))

            let uniqueDivisions = divisionGroup.filter((arr, index, self) =>
                index === self.findIndex((t) => (t.id === arr.id && t.shortDesc === arr.shortDesc)))


            this.seriesCustomerGroup = []
            uniqueDivisions.forEach( x =>{
                  let theName = response.data.filter(f => f.id === x.id)[0].description
                  // console.log(theName)

                  let theData = []

                  response.data.forEach( f => {
                    if (f.id === x.id) {
                      theData.push(parseInt(f.sumResult))
                    }
                  })
                  this.seriesCustomerGroup.push({
                    name: theName,
                    data: theData
                  })

                }
            )

            this.seriesCustomerGroup.sort((a,b) => (a.shortDesc > b.shortDesc)? 1: -1)
          }
      )

    },
    toggleClick(toggleValue){
      // console.log(toggleValue)

      let ftSaleshFilterMonth = new FtSaleshFilter()
      let date = new Date()
      let newStartDate6Month = new Date(date.getFullYear(), date.getMonth()-5, 1)
      let newStartDate3Month = new Date(date.getFullYear(), date.getMonth()-2, 1)
      if (toggleValue =='last3months'){
        ftSaleshFilterMonth.invoiceDateFrom = newStartDate3Month
      }else{
        ftSaleshFilterMonth.invoiceDateFrom = newStartDate6Month
      }


      let endDate = new Date(date.getFullYear(), date.getMonth()+1, 0)
      ftSaleshFilterMonth.invoiceDateTo = endDate

      this.runExtendedFilter(ftSaleshFilterMonth)
      // this.monthPickerChange(this.monthPicker, toggle)

    },

    formattedCurrencyValue(value){
      if (!value){return 0 }
      return parseFloat(value).toLocaleString()
    },

  },
  mounted() {
    this.toggleClick('last6months')
  }
}
</script>

<style scoped>

</style>