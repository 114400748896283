<template>
  <div class="mx-4 elevation-10 rounded-lg pa-4" >
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <div class="subtitle-1 font-weight-bold">TOP BUYERS</div>
        <div class="ml-2 mt-4">
            <v-date-picker
                v-model="monthPicker"
                type="month"
                @change="monthPickerChange"
                color="blue"
                elevation="10"
                class="rounded-lg"
            ></v-date-picker>
        </div>
      </v-col>
      <v-col cols="12" sm="8" md="8">
        <v-row no-gutters class="mb-2">
          <v-col cols="0" sm="6" md="6">
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="itemsTopBuyers"
            :search="search"
            :page.sync="currentPage"
            :items-per-page.sync="pageSize"
            dense
            @click:row="rowTableClick"
        >
          <template v-slot:[`item.number`]="{ index }">
            {{ (index + ((currentPage-1)*pageSize) +1 )}}
          </template>

          <template v-slot:[`item.description`]="{ item }">
            {{item.description}} <span class="grey--text ml-1 caption">{{item.shortDesc}}</span>
            <span class="grey--text ml-1 caption font-italic">{{item.remark1}}</span>
          </template>

          <template v-slot:[`item.sumResult`]="{ item }">
            <div class="text-end">
              <span class="grey--text caption font-weight-light mr-2">{{item.countResult}} rows</span>
              {{ formattedCurrencyValue(item.sumResult) }}
            </div>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="blue-grey lighten-3 rounded-lg">
      <v-col cols="12" sm="4" md="4">
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <div class="yellow--text">
          TOP BUYER TOTAL
        </div>
        <div class="font-weight-bold">
          {{ formattedCurrencyValue(computedItemsTopBuyers) }}
        </div>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <div class="yellow--text">
          WITH ENDUSER PRICE
        </div>
        <div class="">
          {{ formattedCurrencyValue(computedItemsTotalEndUserByCustomer) }}
        </div>
      </v-col>
      <v-col cols="12" sm="2" md="2">
        <div  class="yellow--text">
          TOT DISC
        </div>
        <div class="">
          {{ ((computedItemsTotalEndUserByCustomer-computedItemsTopBuyers)/ computedItemsTotalEndUserByCustomer*100).toFixed(2) }} %
        </div>
      </v-col>


    </v-row>
    <v-dialog v-model="dialogShow" width="500px">
      <v-card class="pa-2">
        <v-card-subtitle class="my-1">
          <span class="font-weight-bold">{{ this.itemSelected.description }}</span>
          <span class="ml-2 grey--text font-weight-bold">{{ this.itemSelected.shortDesc }}</span>
        </v-card-subtitle>
        <v-card-text>
          <div>
            <v-data-table
                :headers="headersSales"
                :items="itemsProductHistory"
                hide-default-footer
                dense
            >
              <template v-slot:[`item.number`]="{ index }">
                {{ (index + ((currentPage-1)*pageSize) +1 )}}
              </template>
              <template v-slot:[`item.sumResult`]="{ item }">
                <div class="text-end">
                  {{ formattedCurrencyValue(parseInt(item.sumResult)) }}
                </div>
              </template>


            </v-data-table>
          </div>

          <div class="blue-grey lighten-3 rounded mt-2 elevation-10">
            <v-sparkline
                class="mt-2"
                :value="sprarkLineValue"
                :gradient="gradient"
                :smooth="radius || false"
                :padding="padding"
                :line-width="width"
                :stroke-linecap="lineCap"
                :gradient-direction="gradientDirection"
                :fill="fill"
                :type="type"
                :auto-line-width="autoLineWidth"
                auto-draw
            >

              <template v-slot:label="item">
                {{ formattedCurrencyValue(item.value) }}
              </template>

            </v-sparkline>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogClose" small class="primary" rounded>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FtSaleshFilter from "@/models/payload/ft-salesh-filter";
import FtSaleshExtService from "@/services/apiservices/ft-sales-ext-service";

const gradients = [
  ['#222'],
  ['#42b3f4'],
  ['red', 'orange', 'yellow'],
  ['purple', 'violet'],
  ['#00c6ff', '#F0F', '#FF0'],
  ['#f72047', '#ffd200', '#1feaea'],
]

export default {
  name: "DashboardTopBuyers",
  props:{
    itemsTopBuyers:[],
    itemsTotalEndUserByCustomer: [],
    ftSaleshFilterMonthTopBuyers:[]
  },
  watch:{
    // monthPicker:{
    //   handler: function (value){
    //     console.log(JSON.stringify(value))
    //   }
    // }
  },
  data (){
    return {
      dialogShow: false,
      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500],

      monthPicker: new Date().toISOString().substr(0, 7),
      search : '',
      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'Nama Product', value: 'description', width:"60%", sortable: false },
        { text: 'Sales Rp', value: 'sumResult', sortable: false },
      ],
      headersSales: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'Bulan', value: 'remark1', width:"40%", sortable: false },
        { text: 'Value Rp', value: 'sumResult', width:"40%", sortable: false },
      ],
      itemSelected: '',
      itemsProductHistory: [],

      width: 2,
      radius: 10,
      padding: 8,
      lineCap: 'round',
      gradient: gradients[5],
      value: [0, 2, 5],
      gradientDirection: 'top',
      gradients,
      fill: false,
      type: 'trend',
      autoLineWidth: false
    }
  },
  computed:{
    computedItemsTopBuyers(){
      let sum =0
      this.itemsTopBuyers.forEach(x=>
          sum += x.sumResult
      )
      return sum
    },
    computedItemsTotalEndUserByCustomer(){
      let sum =0
      this.itemsTotalEndUserByCustomer.forEach(x=>
          sum += x.sumResult
      )
      return sum
    },
    sprarkLineValue(){
      let values = []
      this.itemsProductHistory.forEach( x => {
        values.push(x.sumResult)
      })
      return values
    }
  },
  methods:{
    monthPickerChange(value){

      this.$emit('eventFromDashboardTopBuyers', value)
    },

    rowTableClick(item){
      this.itemSelected = item

      // console.log(JSON.stringify(item))

      let ftSaleshFilterMonth = new FtSaleshFilter()
      ftSaleshFilterMonth = Object.assign({}, this.ftSaleshFilterMonthTopBuyers)

      let startDate = new Date(`${this.monthPicker}-01`)
      let newStartDate6Month = new Date(startDate.getFullYear(), startDate.getMonth()-5, 1)
      ftSaleshFilterMonth.invoiceDateFrom = newStartDate6Month


      let endDate = new Date(startDate.getFullYear(), startDate.getMonth()+1, 0)
      ftSaleshFilterMonth.invoiceDateTo = endDate

      ftSaleshFilterMonth.fcustomerIds = []
      ftSaleshFilterMonth.fcustomerIds.push(item.id)

      FtSaleshExtService.getSumTotalAmountGroupByCustomerAndMonth(ftSaleshFilterMonth).then(
          response =>{
            // console.log(JSON.stringify(response.data))

            this.itemsProductHistory = response.data.sort((a, b) => (a.remark1 > b.remark1)? 1:-1).map( x =>{
              let newValue = x
              x.id = Math.random()
              return newValue
            })

            // console.log(JSON.stringify(response.data.sort((a, b) => (a.remark1 > b.remark1)? 1:-1)))

          }
      )

      this.dialogShow = true
    },

    dialogClose(){
      this.dialogShow = false
    },

    formattedCurrencyValue(value){
      if (!value){return 0 }
      return parseFloat(Math.round(value)).toLocaleString()
    }
  },
  mounted() {
    this.monthPickerChange(this.monthPicker)
  }
}
</script>

<style scoped>

</style>