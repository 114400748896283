<template>
  <div class="my-color-top-left-gradient-3">
    <v-card class="elevation-0">
      <v-card-text>
        <FtSaleshFilterExt
            class="orange rounded-lg pa-2"
            :dashboard="true"
            ref="refFtSaleshFilterExt"
            @runExtendedFilterFromChild="runExtendedFilterFromChild"
        ></FtSaleshFilterExt>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col cols="12">
        <DashboardSalesContrib
            ref="refDashboardSalesContrib"
            :ftSaleshFilter="ftSaleshFilter"
            :brandLabels="brandLabels"
            :brandSeries="brandSeries"
            :group3Labels="group3Labels"
            :group3Series="group3Series"
        ></DashboardSalesContrib>
      </v-col>
    </v-row>

    <v-row v-if="showDashboardFinance">
      <v-col cols="12">
        <DashboardFinanceReport
          :salesByDivision="salesByDivisionSalesOnly"
          :itemsAccAccount="itemsAccAccount"
          :itemsAccJournald="itemsAccJournald"
          :dateDiffFromRange="dateDiffFromRange"
          :ftSaleshFilter="ftSaleshFilter"
        ></DashboardFinanceReport>
      </v-col>
    </v-row>

    <v-row v-if="showDashboardSalesTrend">
      <v-col cols="12">
        <DashboardSalesTrend
            ref="refDashboardSalesTrend"
            :salesByDivision="salesByDivision"
        ></DashboardSalesTrend>
      </v-col>
    </v-row>

    <v-row v-if="showDashboardTopProducts">
      <v-col cols="12">
        <DashboardProductTerlaris
            ref="refDashboardProductTerlaris"
            :itemsQtySales="itemsQtySales"
            :ftSaleshFilterMonthTopItems="ftSaleshFilterMonthTopItems"
            @eventFromDashboardProductTerlarisQty="eventFromDashboardProductTerlarisQty"
            @eventFromDashboardProductTerlarisValue="eventFromDashboardProductTerlarisValue"
        ></DashboardProductTerlaris>
      </v-col>
    </v-row>

    <v-row v-if="showDashboardTopBuyers">
      <v-col cols="12">
        <DashboardTopBuyers
            ref="refDashboardTopBuyers"
            :itemsTopBuyers="itemsTopBuyers"
            :itemsTotalEndUserByCustomer="itemsTotalEndUserByCustomer"
            :ftSaleshFilterMonthTopBuyers="ftSaleshFilterMonthTopBuyers"
            @eventFromDashboardTopBuyers="eventFromDashboardTopBuyers"
        ></DashboardTopBuyers>
      </v-col>
    </v-row>

  </div>

</template>

<script>
import FtSaleshService from '@/services/apiservices/ft-salesh-service';
import FileService from "@/services/apiservices/file-service"

import FtSalesh from '@/models/ft-salesh'

import EStatusPengiriman, {EStatusPengirimans} from "@/models/e-status-pengiriman";

import FtSaleshFilter from "@/models/payload/ft-salesh-filter";
import ERole from "@/models/e-role";

import { dateFormattedDatefnsWithTime,  dateFormattedDatefns, dateFormattedDatefnsShort, dateFormattedDatefnsWithTimeShort} from "@/utils/date-utils"
import FTaxService from "@/services/apiservices/f-tax-service";
import FtSaleshFilterExt from "@/components/sales/FtSaleshFilterExt";
import DashboardSalesContrib from "@/components/dashboard/DashboardSalesContrib";
import FtSaleshExtService from "@/services/apiservices/ft-sales-ext-service";
import DashboardFinanceReport from "@/components/dashboard/DashboardFinanceReport";
import DashboardProductTerlaris from "@/components/dashboard/DashboardProductTerlaris";
import DashboardSalesTrend from "@/components/dashboard/DashboardSalesTrend";
import DashboardTopBuyers from "@/components/dashboard/DashboardTopBuyers";
import AccJournaldItemsService from "@/services/apiservices/acc-journald-items-service";
import AccAccountService from "@/services/apiservices/acc-account-service";

export default {
  components: {
    DashboardTopBuyers,
    DashboardSalesTrend,
    DashboardProductTerlaris,
    DashboardSalesContrib,
    FtSaleshFilterExt,
    DashboardFinanceReport
  },
  data () {
    return {
      loading: false,
      title: 'DASHBOARD',

      showFilter: false,
      ftSaleshFilter: new FtSaleshFilter(),
      ftSaleshFilterMonthTopBuyers: new FtSaleshFilter(),
      ftSaleshFilterMonthTopItems: new FtSaleshFilter(),

      // filterFdivisions: [],
      // filterFsalesmans: [],
      // filterFstores: [],
      // filterFexpedisies:[],
      // filterFmaterials: [],
      // filterEstatusPengiriman: [],
      // filterFwarehouses: [],

      dateDiffFromRange: 1,

      filterByGroupMaterialOrdered: [],
      itemsGroupMaterialOrdered:[],

      snackbar: false,
      snackBarMesage: '',

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 20, 40, 75, 150, 500, 1000, 2500, 5000],

      ftSaleshs:[
        new FtSalesh(1, "")
      ],
      // itemsFDivision: [],

      itemsEStatusPengiriman: EStatusPengirimans,
      eStatusPengiriman: EStatusPengiriman,

      boldClass: 'font-weight-bold',
      redClass: 'red--text',

      summaryCount: [],
      summarySum: [],

      brandLabels: [],
      brandSeries: [],
      group3Labels: [],
      group3Series: [],

      salesByDivision: [],
      salesByDivisionSalesOnly: [],

      itemsQtySales: [],
      itemsTopBuyers: [],
      itemsTotalEndUserByCustomer: [],

      itemsAccAccount: [],
      itemsAccJournald: [],

    }

  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) {
          if (this.showFilter){
            this.runExtendedFilter()
            // console.log("here bos")
          }else {
            // console.log("Not here")
            this.fetchFtSalesh()
          }
        }

      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage===1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)

          // this.fetchFtSalesh()
          if (this.showFilter){
            this.runExtendedFilter()
          }else {
            this.fetchFtSalesh()
          }

        }

        // console.log("page size = " + value + " >> " + this.totalPages)

      }
    },

  },
  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    haveRoleAdminMutlak(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (
            this.currentUser.roles.includes("ROLE_ADMIN")
        ){
          isValid=true
        }
      }
      return isValid
    },
    haveRoleAdmin(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (
            this.currentUser.roles.includes("ROLE_ADMIN") ||
            this.currentUser.roles.includes("ROLE_ADMIN1") ||
            this.currentUser.roles.includes("ROLE_ADMIN2")
        ){
          isValid=true
        }
      }
      return isValid
    },

    showDashboardFinance() {
      return [ERole.ROLE_ADMIN, ERole.ROLE_ACCOUNT].some(x => this.currentUser.roles.includes(x))
    },

    showDashboardSalesTrend() {
      return [ERole.ROLE_ADMIN, ERole.ROLE_SALES, ERole.ROLE_USER, ERole.ROLE_FINANCE, ERole.ROLE_CS, ERole.ROLE_ACCOUNT].some(x => this.currentUser.roles.includes(x))
    },

    showDashboardTopProducts() {
      return [ERole.ROLE_ADMIN, ERole.ROLE_SALES, ERole.ROLE_USER, ERole.ROLE_FINANCE, ERole.ROLE_CS, ERole.ROLE_ACCOUNT, ERole.ROLE_INVENTORY].some(x => this.currentUser.roles.includes(x))
    },

    showDashboardTopBuyers() {
      return [ERole.ROLE_ADMIN, ERole.ROLE_SALES, ERole.ROLE_USER, ERole.ROLE_FINANCE, ERole.ROLE_CS, ERole.ROLE_ACCOUNT].some(x => this.currentUser.roles.includes(x))
    },

    formMode: {
      get () {
        return this.$store.state.sales.formMode
      },
      set (value) {
        this.$store.dispatch('sales/updateFormMode', value)
      }
    },

    ftSaleshsFiltered(){
      return this.ftSaleshs
    },

    itemsFDivision(){
      return this.$store.state.data.listFDivision
    },
    itemsFMaterial(){
      return this.$store.state.data.listFMaterial
    },
    itemsFExpedisi(){
      return this.$store.state.data.listFExpedisi
    },
    itemsFWarehouse(){
      return this.$store.state.data.listFWarehouse
    },

    itemsFSalesman(){
      const listFSalesman = []

      if (this.currentUser.salesmanOf > 0 &&  [ERole.ROLE_CS].some(x =>this.currentUser.roles.includes(x)) ) {
        this.$store.state.data.listFSalesman.filter(x => x.id  === this.currentUser.salesmanOf).forEach( item =>{
          listFSalesman.push(item)
        })
      }else {
        this.$store.state.data.listFSalesman.forEach( item =>{
          listFSalesman.push(item)
        })
      }

      return listFSalesman
    },
    itemsFStore(){
      return this.$store.state.data.listFStore
    },

    itemsFCustomer(){
      return this.$store.state.data.listFCustomer
    },
    itemsFCustomerGroup(){
      return this.$store.state.data.listFCustomerGroup
    },


  },
  methods: {

    runExtendedFilterFromChild(value){
      this.runExtendedFilter(value)
    },
    runExtendedFilter(value) {
      // console.log(value)


      this.ftSaleshFilter = new FtSaleshFilter()

      if (value instanceof FtSaleshFilter ) {
        this.ftSaleshFilter = value
      }else {
        this.ftSaleshFilter.invoiceDateFrom =this.$refs.refFtSaleshFilterExt.datePickerRangeInv.startDate
        this.ftSaleshFilter.invoiceDateTo =this.$refs.refFtSaleshFilterExt.datePickerRangeInv.endDate
      }


      this.ftSaleshFilter.pageNo = this.currentPage
      this.ftSaleshFilter.pageSize = this.pageSize
      this.ftSaleshFilter.sortBy = "id"
      this.ftSaleshFilter.order = "DESC"
      this.ftSaleshFilter.search = this.search

      /**
       * Namun nanti untuk rata-rata jika dateDiff 0 >> maka hari sama >> maka pembaginya adalah satu
       */
      let difference_In_Time = this.$refs.refFtSaleshFilterExt.datePickerRangeInv.endDate.getTime() - this.$refs.refFtSaleshFilterExt.datePickerRangeInv.startDate.getTime()
      this.dateDiffFromRange = Math.round( difference_In_Time / (1000 * 60 * 60  * 24) )

      this.ftSaleshFilter.type =0
      FtSaleshService.getAllFtSaleshContainingExtSummary(this.ftSaleshFilter).then(
           response =>{
             // console.log(JSON.stringify(response.data))

             this.summaryCount = response.data
           },
           error => {
             console.log(error)
           }
      )

      this.ftSaleshFilter.type =1
      FtSaleshService.getAllFtSaleshContainingExtSummary(this.ftSaleshFilter).then(
          response =>{
            // console.log(JSON.stringify(response.data))

            this.summarySum = response.data
          },
          error => {
            console.log(error)
          }
      )

      FtSaleshExtService.getAllFtSaleshExtByGroup3AndBrandAndSupplier(this.ftSaleshFilter).then(
          response => {

            // console.log(JSON.stringify(response.data))

            const { itemsGroup3, itemsBrand} = response.data
            this.brandLabels = []
            this.brandSeries = []
            this.group3Labels = []
            this.group3Series = []

            itemsBrand.sort((a,b) => (a.sumResult < b.sumResult)? 1 : -1 ).forEach(item => {
              let shortName =  item.description
              if (item.description.length > 10){
                shortName = item.description.substr(0, 9)
              }
              this.brandLabels.push(shortName)
              // this.brandLabels.push(item.description)

              this.brandSeries.push(parseInt(item.sumResult))

            })

            itemsGroup3.sort((a,b) => (a.sumResult < b.sumResult)? 1 : -1 ).forEach(item => {
              let shortName =  item.description
              if (item.description.length > 10){
                shortName = item.description.substr(0, 9)
              }
              this.group3Labels.push(shortName)
              // this.group3Labels.push(item.description)

              this.group3Series.push(parseInt(item.sumResult))

            })
          }
      )

      this.$refs.refDashboardSalesContrib.reloadChildren(this.ftSaleshFilter)

      FtSaleshExtService.getAllFtSaleshExtByDivision(this.ftSaleshFilter).then(
          response =>{
            this.salesByDivision = response.data
            // console.log(JSON.stringify(response.data))
          }
      )




      AccJournaldItemsService.sumAllByTrDateBetweenAndCompanyGroupByAccAccount(this.ftSaleshFilter).then(
          response =>{
            // console.log(JSON.stringify(response.data))
            this.itemsAccJournald = response.data

          }
      )

      const ftSaleshFilterSalesOnly = this.ftSaleshFilter
      // ftSaleshFilterSalesOnly.tipeFakturIds = [ 'F' ]
      FtSaleshExtService.getAllFtSaleshExtByDivisionNoRet(ftSaleshFilterSalesOnly).then(
          response =>{
            this.salesByDivisionSalesOnly = response.data
            // console.log(JSON.stringify(response.data))
          }
      )

    },
    eventFromDashboardProductTerlarisQty(value){

      this.ftSaleshFilterMonthTopItems = this.ftSaleshFilter

      let startDate = new Date(`${value}-01`)
      this.ftSaleshFilterMonthTopItems.invoiceDateFrom = startDate
      let endDate = new Date(startDate.getFullYear(), startDate.getMonth()+1, 0)
      this.ftSaleshFilterMonthTopItems.invoiceDateTo = endDate

      FtSaleshExtService.getSumQtyFmaterialSalesdItems(this.ftSaleshFilterMonthTopItems).then(
          response =>{
            this.itemsQtySales = response.data.sort((a, b) => (a.sumResult < b.sumResult)? 1:-1)
            // console.log(JSON.stringify(response.data))

          }
      )

    },
    eventFromDashboardProductTerlarisValue(value){

      /**
       * Filternya mandiri
       */
      this.ftSaleshFilterMonthTopItems = this.ftSaleshFilter

      let startDate = new Date(`${value}-01`)
      this.ftSaleshFilterMonthTopItems.invoiceDateFrom = startDate
      let endDate = new Date(startDate.getFullYear(), startDate.getMonth()+1, 0)
      this.ftSaleshFilterMonthTopItems.invoiceDateTo = endDate

      FtSaleshExtService.getSumValueFmaterialSalesdItems(this.ftSaleshFilterMonthTopItems).then(
          response =>{
            this.itemsQtySales = response.data.sort((a, b) => (a.sumResult < b.sumResult)? 1:-1)
            // console.log(JSON.stringify(response.data))

          }
      )
    },

    eventFromDashboardTopBuyers(value){

      this.ftSaleshFilterMonthTopBuyers = this.ftSaleshFilter

      let startDate = new Date(`${value}-01`)
      this.ftSaleshFilterMonthTopBuyers.invoiceDateFrom = startDate
      let endDate = new Date(startDate.getFullYear(), startDate.getMonth()+1, 0)
      this.ftSaleshFilterMonthTopBuyers.invoiceDateTo = endDate

      FtSaleshExtService.getSumTotalAmountGroupByCustomer(this.ftSaleshFilterMonthTopBuyers).then(
          response =>{
            this.itemsTopBuyers = response.data.sort((a, b) => (a.sumResult < b.sumResult)? 1:-1)
            // console.log(JSON.stringify(response.data))

          }
      )

      FtSaleshExtService.getSumTotalEndUserByCustomer(this.ftSaleshFilterMonthTopBuyers).then(
          response =>{
            // console.log(JSON.stringify(response.data))
            this.itemsTotalEndUserByCustomer = response.data
          }
      )



    },


    fetchParent(){
      this.$store.dispatch('data/loadFDivisionByOrgLevel')

      let fsalesmanBean = 0
      if (this.currentUser.roles !== undefined){
        if (this.currentUser.salesmanOf > 0 &&  [ERole.ROLE_CS, ERole.ROLE_AGEN, ERole.ROLE_RSL_1, ERole.ROLE_RSL_2].some(x =>this.currentUser.roles.includes(x)) ) {
          fsalesmanBean = this.currentUser.salesmanOf
        }
      }

      /**
       * Pada suatu saat akan tidak mungkin untuk load semua Fcustomer
       */
      const payload = {'fsalesmanBean': fsalesmanBean}
      payload.toString()

      this.$store.dispatch('data/loadFWarehouseExpedisiAvailable')

      this.$store.dispatch('data/loadFMaterial')
      this.$store.dispatch('data/loadFExpedisi')
      this.$store.dispatch('data/loadFWarehouse')

      if (this.currentUser.salesmanOf > 0 &&  [ERole.ROLE_CS, ERole.ROLE_AGEN, ERole.ROLE_RSL_1, ERole.ROLE_RSL_2].some(x =>this.currentUser.roles.includes(x)) ) {
        this.$store.dispatch('data/loadFSalesmanChildrenAndMe')
      }else {
        this.$store.dispatch('data/loadFSalesman')
      }
      // this.$store.dispatch('data/loadFSalesman')

      this.$store.dispatch('data/loadFPayMethod')
      // this.$store.dispatch('data/loadFStore')

      FTaxService.getAllFTax().then(
          response =>{
            this.$store.dispatch('sales/reloadItemsFTax', response.data)
          }
      )
      AccAccountService.getAllAccAccountByCompany().then(
          response =>{
            this.itemsAccAccount = response.data
            // console.log(JSON.stringify(response.data))
          }
      )


    },

    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },

    eventEditFormDialog1() {
    },
    eventCreateFormDialog1() {
    },


    removeAutoCompleteFExpedisi (item) {
      const index = this.filterFexpedisies.indexOf(item.id)
      if (index >= 0) this.filterFexpedisies.splice(index, 1)
    },
    removeAutoCompleteFWarehouse (item) {
      const index = this.filterFwarehouses.indexOf(item.id)
      if (index >= 0) this.filterFwarehouses.splice(index, 1)
    },

    removeAutoCompleteFMaterial (item) {
      const index = this.filterFmaterials.indexOf(item.id)
      if (index >= 0) this.filterFmaterials.splice(index, 1)
    },
    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0]
      }else {
        return undefined
      }
    },
    lookupFSalesman (fsalesmanBean) {
      const str = this.itemsFSalesman.filter(x => x.id===fsalesmanBean)

      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFCustomer (fcustomerBean) {
      const str = this.itemsFCustomer.filter(x => x.id===fcustomerBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFStore (fstoreBean) {
      const str = this.itemsFStore.filter(x => x.id===fstoreBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFExpedisi (fexpedisiBean) {
      const str = this.itemsFExpedisi.filter(x => x.id===fexpedisiBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFWarehouseKode1 (fwarehouseBean) {
      const str = this.itemsFWarehouse.filter(x => x.id===fwarehouseBean)
      if (str.length>0){
        return str[0].kode1
      }else {
        return '-'
      }
    },

    lookupFWarehouse (fwarehouseBean) {
      const str = this.itemsFWarehouse.filter(x => x.id===fwarehouseBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFMaterialPcode (fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return `${str[0].pcode}`
      }else {
        return '-'
      }
    },
    lookupFMaterial (fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupEStatusPengiriman (statusPengiriman) {
      const str = this.itemsEStatusPengiriman.filter(x => x.id===statusPengiriman)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupEStatusPengirimanColor (statusPengiriman) {
      const str = this.itemsEStatusPengiriman.filter(x => x.id===statusPengiriman)
      if (str.length>0){
        return `${str[0].color}`
      }else {
        return '-'
      }
    },
    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return require('@/assets/images/no_image_available.jpeg')
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },
    lookupImageUrlLazy(){
      return require('@/assets/images/no_image_available.jpeg')
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      return prefix + "" + parseFloat(value).toLocaleString()
    },

    formattedPecahan(value){
      if(! value){ return "0"}
      return parseFloat(value).toFixed(1)
    },

    dateFormattedDatefns(value){
      return dateFormattedDatefns(value)
    },
    dateFormattedDatefnsShort(value){
      return dateFormattedDatefnsShort(value)
    },
    dateFormattedDatefnsWithTime(value){
      return dateFormattedDatefnsWithTime(value)
    },
    dateFormattedDatefnsWithTimeShort(value){
      return dateFormattedDatefnsWithTimeShort(value)
    },

    stringMaxLenght(value){
      if (value ==undefined) value = ''
      let str = value
      if (value.length>6) str = value.substr(0, 5)
      return str
    }

  },

  mounted() {

    if (!this.currentUser) {
      this.$router.push('/login')
    }else {
      // this.fetchParent()
      //
      // let toDay = new Date()
      // let startDate = new Date(toDay.getFullYear(), toDay.getMonth(), 1)
      // let endDate = new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate())
      // this.$refs.refFtSaleshFilterExt.datePickerRangeInv.startDate = startDate
      // this.$refs.refFtSaleshFilterExt.datePickerRangeInv.endDate = endDate

      // this.runExtendedFilter()

    }


  }

}
</script>

<style scoped>
.my-color-top-left-gradient-3 {
  background-image: linear-gradient(to bottom left, #e9ebf3 5%, transparent 60%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

}

</style>
