import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class AccJournaldItemsService {
    getApiUrl() {
        return API_URL
    }
    getAllAccJournaldItems(){
        return axios.get(API_URL + `getAllAccJournaldItems`, { headers: authHeader() });
    }
    getAllAccJournaldItemsContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllAccJournaldItems`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllAccJournaldItemsContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getAccJournaldItemsById(id){
        return axios.get(API_URL + `getAccJournaldItemsById/${id}`, { headers: authHeader() });
    }
    getAllAccJournaldItemsByParent(id){
        return axios.get(API_URL + `getAllAccJournaldItemsByParent/${id}`, { headers: authHeader() });
    }
    getAllAccJournaldItemsByParentIds(ids){
        return axios.get(API_URL + `getAllAccJournaldItemsByParentIds/${ids}`, { headers: authHeader() });
    }



    getAllByTrDateBetweenAndCompany(item){
        return axios.post(API_URL + `getAllByTrDateBetweenAndCompany`, item, { headers: authHeader() });
    }
    sumAllByTrDateBetweenAndCompanyGroupByAccAccount(item){
        return axios.post(API_URL + `sumAllByTrDateBetweenAndCompanyGroupByAccAccount`, item, { headers: authHeader() });
    }
    sumAllByTrDateBetweenAndCompanyGroupByAccAccountAndMonth(item){
        return axios.post(API_URL + `sumAllByTrDateBetweenAndCompanyGroupByAccAccountAndMonth`, item, { headers: authHeader() });
    }
    getAllByTrDateBetweenAndAccAccountIds(item){
        return axios.post(API_URL + `getAllByTrDateBetweenAndAccAccountIds`, item, { headers: authHeader() });
    }
    sumAllByTrDateBetweenAndAccAccountIdsGroupByAccAccount(item){
        return axios.post(API_URL + `sumAllByTrDateBetweenAndAccAccountIdsGroupByAccAccount`, item, { headers: authHeader() });
    }
    sumAllByTrDateBetweenAndAccAccountIdsGroupByAccAccountAndMonth(item){
        return axios.post(API_URL + `sumAllByTrDateBetweenAndAccAccountIdsGroupByAccAccountAndMonth`, item, { headers: authHeader() });
    }


    updateAccJournaldItems(item){
        return axios.put(API_URL + `updateAccJournaldItems/${item.id}`, item, {headers: authHeader()})
    }
    updateAccJournaldItemsCancelQtyByParent(ftSaleshBean){
        return axios.put(API_URL + `updateAccJournaldItemsCancelQtyByParent/${ftSaleshBean.id}`, ftSaleshBean,{headers: authHeader()})
    }

    createAccJournaldItems(item){
        return axios.post(API_URL + `createAccJournaldItems`, item, {headers: authHeader()})
    }
    deleteAccJournaldItems(id){
        return axios.delete(API_URL + `deleteAccJournaldItems/${id}`, {
            headers: authHeader()
        });
    }

    deleteAccJournaldItemsAvatarByParent(fkegiatanBean){
        return axios.delete(API_URL + `deleteAccJournaldItemsAvatarByParent/${fkegiatanBean}`, {
            headers: authHeader()
        });
    }

    createAccJournaldItemsAvatar(item){
        return axios.post(API_URL + `createAccJournaldItemsAvatar`, item, {headers: authHeader()})
    }

    deleteAllAccJournaldItems(itemIds){

        return axios.delete(API_URL + `deleteAllAccJournaldItems`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new AccJournaldItemsService()