<template>
  <DashboardUtama v-if="false"/>
</template>

<script>
import DashboardUtama from "../components/dashboard/Dashboard";
export default {
  name: "Dashboard",
  components:{
    DashboardUtama
  }
}
</script>

<style scoped>

</style>