<template>
  <div class="mx-4 elevation-10 rounded-lg pa-4" >
    <v-row>
      <v-col cols="12" sm="4" md="4">
          <div class="subtitle-1 font-weight-black green--text text--darken-2 ml-2">PRODUK TERLARIS</div>
      </v-col>
      <v-col cols="12" md="8" sm="8">
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="4" md="4">
        <div class="ml-2 mt-4 mb-2">
          <v-date-picker
              v-model="monthPicker"
              type="month"
              @change="monthPickerChange"
              color="green"
              elevation="10"
              class="rounded-lg"
          ></v-date-picker>
        </div>
      </v-col>
      <v-col cols="12" sm="8" md="8">
        <v-row no-gutters class="mb-2">
          <v-col cols="12" sm="6" md="6">
            <div>
              <v-btn-toggle
                  v-model="toggleValue"
                  dense
                  color="green"
              >
                <v-btn value="inQty"  @click="toggleClick('inQty')">
                  <span class="caption font-weight-bold">Quantity</span>
                </v-btn>

                <v-btn value="inValue"  @click="toggleClick('inValue')">
                  <span class="caption font-weight-bold">Value</span>
                </v-btn>

              </v-btn-toggle>
            </div>

          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="itemsQtySales"
            :search="search"
            :page.sync="currentPage"
            :items-per-page.sync="pageSize"
            dense
            @click:row="rowTableClick"
        >
          <template v-slot:[`item.number`]="{ index }">
            {{ (index + ((currentPage-1)*pageSize) +1 )}}
          </template>

          <template v-slot:[`item.description`]="{ item }">
            {{item.description}} <span class="grey--text ml-1 caption">{{item.shortDesc}}</span>
          </template>

          <template v-slot:[`item.sumResult`]="{ item }">
            <div class="text-end mr-10">
              <span class="grey--text mr-2 caption font-weight-light">{{item.countResult}} rows</span>
              <span>{{ formattedCurrencyValue(item.sumResult) }}</span>
            </div>
          </template>

        </v-data-table>

      </v-col>
    </v-row>

    <v-dialog v-model="dialogShow" width="500px">
      <v-card class="px-2 pt-2">
        <v-card-subtitle class="my-1">
          <span class="font-weight-bold blue--text">{{ this.itemSelected.pname }}</span>
          <span class="ml-2 grey--text font-weight-bold">{{ this.itemSelected.pcode }}</span>
        </v-card-subtitle>
        <v-card-text>
          <v-tabs
              v-model="tab"
              background-color="success"
              flat
              prominent
              centered
              class="rounded-lg"
          >
            <v-tab key="tab1">Monthly</v-tab>
            <v-tab key="tab2">Last 10Days</v-tab>
            <v-tab key="tab3">Per-Hour</v-tab>
          </v-tabs>
          <v-tabs-slider></v-tabs-slider>

          <v-tabs-items  v-model="tab">
            <v-tab-item key="tab1">
              <div>
                <v-data-table
                    :headers="headersProductMonth"
                    :items="itemsProductHistorys"
                    hide-default-footer
                    dense
                >
                  <template v-slot:[`item.number`]="{ index }">
                    {{ (index + ((currentPage-1)*pageSize) +1 )}}
                  </template>
                  <template v-slot:[`item.sumResult`]="{ item }">
                    <div class="text-end">
                      {{ formattedCurrencyValue(item.sumResult)}}
                    </div>
                  </template>

                  <template v-slot:[`item.inKrt`]="{ item }">
                    <div class="caption grey--text">
                      {{ lookupUom1234StringUom(itemSelected, parseInt(item.sumResult)) }}
                    </div>
                  </template>

                </v-data-table>
              </div>

              <div class="blue-grey lighten-3 rounded mt-2 elevation-10">
                <v-sparkline
                    class="mt-2"
                    :value="sprarkLineValue"
                    :gradient="gradient"
                    :smooth="radius || false"
                    :padding="padding"
                    :line-width="width"
                    :stroke-linecap="lineCap"
                    :gradient-direction="gradientDirection"
                    :fill="fill"
                    :type="type"
                    :auto-line-width="autoLineWidth"
                    auto-draw
                >

                  <template v-slot:label="item">
                    {{ formattedCurrencyValue(item.value) }}
                  </template>

                </v-sparkline>
              </div>
            </v-tab-item>
            <v-tab-item key="tab2">
              <div>
                <v-data-table
                    :headers="headersProductDate"
                    :items="itemsProductHistoryDays"
                    :page.sync="currentPageLast10Days"
                    :items-per-page.sync="pageSizeLast10Days"
                    dense
                >
                  <template v-slot:[`item.number`]="{ index }">
                    {{ (index + ((currentPageLast10Days-1)*pageSizeLast10Days) +1 )}}
                  </template>

                  <template v-slot:[`item.sumResult`]="{ item }">
                    <div class="text-end">
                      {{ formattedCurrencyValue(item.sumResult) }}
                    </div>
                  </template>

                  <template v-slot:[`item.inKrt`]="{ item }">
                    <div class="caption grey--text">
                      {{ lookupUom1234StringUom(itemSelected, parseInt(item.sumResult)) }}
                    </div>
                  </template>

                </v-data-table>
              </div>

              <div class="blue-grey lighten-3 rounded mt-2 elevation-10">
                <v-sparkline
                    class="mt-2"
                    :value="sprarkLineValueDays"
                    :gradient="gradient"
                    :smooth="radius || false"
                    :padding="padding"
                    :line-width="width"
                    :stroke-linecap="lineCap"
                    :gradient-direction="gradientDirection"
                    :fill="fill"
                    :type="type"
                    :auto-line-width="autoLineWidth"
                    auto-draw
                >

                  <template v-slot:label="item">
                    {{item.index+1}}
                  </template>

                </v-sparkline>
              </div>

            </v-tab-item>
            <v-tab-item key="tab3">
              <div class="text-center mt-10">
                <span><v-icon>mdi-lock</v-icon></span>
                <span class="ml-1 headline font-weight-bold pink--text">Rp. 1.200.000</span>
                <div>(Hubungi Kami)</div>
              </div>
            </v-tab-item>
          </v-tabs-items>


        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogClose" small class="primary" rounded>Close</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>

import FtSaleshFilter from "@/models/payload/ft-salesh-filter";
import FtSaleshExtService from "@/services/apiservices/ft-sales-ext-service";
import KonversiProductHelper from "@/services/konversi-product-helper";

const gradients = [
  ['#222'],
  ['#42b3f4'],
  ['red', 'orange', 'yellow'],
  ['purple', 'violet'],
  ['#00c6ff', '#F0F', '#FF0'],
  ['#f72047', '#ffd200', '#1feaea'],
]

export default {
  name: "DashboardProductTerlaris",
  props:{
    itemsQtySales:[],
    ftSaleshFilterMonthTopItems: Object
  },
  watch:{
    // monthPicker:{
    //   handler: function (value){
    //     console.log(JSON.stringify(value))
    //   }
    // }

  },
  data (){
    return {
      tab: 'tab1',
      dialogShow: false,
      toggleValue: 'inQty',
      currentPage: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500],

      currentPageLast10Days: 1,
      pageSizeLast10Days: 10,


      monthPicker: new Date().toISOString().substr(0, 7),
      search : '',
      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'Nama Product', value: 'description', width:"60%", sortable: false },
        { text: 'Sold', value: 'sumResult', sortable: false },
      ],
      headersProductMonth: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'Bulan', value: 'remark1', width:"20%", sortable: false },
        { text: 'Sold In Pcs', value: 'sumResult', width:"30%", sortable: false },
        { text: 'InKrt', value: 'inKrt', width:"50%", sortable: false },
      ],
      headersProductDate: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'Date', value: 'remark1', width:"30%", sortable: false },
        { text: 'Sold In Pcs', value: 'sumResult', width:"25%", sortable: false },
        { text: 'InKrt', value: 'inKrt', width:"40%", sortable: false },
      ],

      itemSelected: '',
      itemsProductHistorys: [],
      itemsProductHistoryDays: [],

      width: 2,
      radius: 10,
      padding: 8,
      lineCap: 'round',
      gradient: gradients[5],
      value: [0, 2, 5],
      gradientDirection: 'top',
      gradients,
      fill: false,
      type: 'trend',
      autoLineWidth: false,

    }
  },
  computed:{
    sprarkLineValue(){
      let values = []
      this.itemsProductHistorys.forEach(x => {
        values.push(x.sumResult)
      })
      return values
    },
    sprarkLineValueDays(){
      let values = []
      this.itemsProductHistoryDays.forEach(x => {
        values.push(x.sumResult)
      })
      return values
    },

    itemsFMaterial(){
      return this.$store.state.data.listFMaterial
    }

  },
  methods:{
    lookupUom1234StringUom(fmaterial, pcsOrUom4){
      return KonversiProductHelper.getUom1234StringUom(fmaterial, pcsOrUom4)
    },

    rowTableClick(item){
      this.itemSelected = this.lookupFMaterial(item.id)

      // console.log(JSON.stringify(this.itemSelected))

      let ftSaleshFilterMonth = new FtSaleshFilter()
      ftSaleshFilterMonth = Object.assign({}, this.ftSaleshFilterMonthTopItems)

      let startDate = new Date(`${this.monthPicker}-01`)
      let newStartDate3Month = new Date(startDate.getFullYear(), startDate.getMonth()-5, 1)
      ftSaleshFilterMonth.invoiceDateFrom = newStartDate3Month

      let endDate = new Date(startDate.getFullYear(), startDate.getMonth()+1, 0)
      ftSaleshFilterMonth.invoiceDateTo = endDate

      ftSaleshFilterMonth.fmaterialIds = []
      ftSaleshFilterMonth.fmaterialIds.push(item.id)

      this.itemsProductHistorys = []
      FtSaleshExtService.getSumQtyFmaterialSalesdItemsGroupByMaterialAndMonth(ftSaleshFilterMonth).then(
          response =>{
            this.itemsProductHistorys = response.data.sort((a, b) => (a.remark1 > b.remark1)? 1:-1).map(x =>{
                let newValue = x
                x.id = Math.random()
                return newValue
            })
          }
      )


      let nowDate = new Date()
      let newStartDate10Days = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate()-10)
      ftSaleshFilterMonth.orderDateFrom = newStartDate10Days
      ftSaleshFilterMonth.orderDateTo = nowDate
      this.itemsProductHistoryDays = []
      FtSaleshExtService.getSumQtyFmaterialSalesdItemsGroupByMaterialAndDay(ftSaleshFilterMonth).then(
          response =>{

            // console.log(JSON.stringify(response.data.length))

            this.itemsProductHistoryDays = response.data.sort((a, b) => (a.remark1 > b.remark1)? 1:-1).map( x =>{
              let newValue = x
              x.id = Math.random()
              return newValue
            })
          }
      )

      this.dialogShow = true
    },
    dialogClose(){
      this.dialogShow = false
    },
    monthPickerChange(value, toggle){
      if (toggle == 'inValue'){
        this.$emit('eventFromDashboardProductTerlarisValue', value)
      }else {
        this.$emit('eventFromDashboardProductTerlarisQty', value)
      }
    },
    toggleClick(toggleValue){
      this.monthPickerChange(this.monthPicker, toggleValue)
    },

    formattedCurrencyValue(value){
      if (!value){return 0 }
      return parseFloat(Math.round(value)).toLocaleString()
    },
    lookupFMaterial (fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    }

  },
  mounted() {
    this.monthPickerChange(this.monthPicker)
  }
}
</script>

<style scoped>

</style>